/* @import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap'); */

.c-background {
    width: 100vw;
    min-height: 100vh;
    background-color: black;
    background-image: radial-gradient(rgba(0, 150, 0, 0.438), black 90%);
}

.c-font1 {
    font-family: 'Press Start 2P';
    color: #00b700;
    font-size: 15px;
}

.c-font2 {
    font-family: 'Press Start 2P';
    color: #00b700;
    font-size: 25px;
    text-decoration: underline;
}

.c-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 100%;
  padding-top: 80px;
  gap:30px;
}

.c-select {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}

.c-inner-cont {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.NFTs {
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.hover {
    cursor:pointer;
    transition: transform 0.05s ease-in-out 0s;
    transition: 0.25s;
} 
  
.hover:hover {
    transform: scale(1.05);
}

@media screen and (max-width:930px){
    .c-container {
        padding-top: 20px;
    }
}